<template>
  <div>
    <div class="bg"></div>
    <div class="coupon_wrap">
      <div class="coupon">
        <h2>订单编号或激活码</h2>
        <div class="coupon_content">
          <p>请绑定订单编号或激活码</p>
          <input type="text" v-model="code" placeholder="请输入订单编号或激活码" />
          <el-button type="primary" @click="bindFun">确定</el-button>
        </div>
        <div class="close" @click="closeFun">
          <img src="../assets/images/close.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "binding",
  props: ["type"],
  data() {
    return {
      isShow: false,
      code: "",
    };
  },
  mounted() {},
  methods: {
    closeFun() {
      this.$parent.popStatus = false;
    },
    bindFun() {
      this.$api.POST500(
        this.$face.bindbaodian,
        {
          order_code_no: this.code,
        },
        (res) => {
          if (res.code == 200) {
            this.$parent.popStatus = false;
            this.$utile.prompt("success", "绑定成功");
            if (this.type == 1) {
              this.$parent.getUser();
              this.$parent.$parent.$parent.getUser();
            } else {
              let userInfo = JSON.parse(localStorage.getItem("userInfo"));
              userInfo.is_baodian = 1;
              userInfo.bind_bd_code = this.code;
              this.$parent.$parent.getUser();
              localStorage.setItem("userInfo", JSON.stringify(userInfo));
              setTimeout(() => {
                this.$router.push({
                  name: "deformation",
                });
              }, 1500);
            }
            
          } else {
            let scrollTop=document.documentElement.clientHeight/2-30;
            //this.$utile.prompt("error", res.msg+'11111');
            //console.log(scrollTop);
            this.$message({
          showClose: true,
          message: res.msg,
          type: 'error',
          offset:scrollTop
        });
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.bg {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
  z-index: 99;
}
.coupon_wrap {
  width: 545px;
  height: 288px;
  background-color: #fff;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
}
.coupon {
  position: relative;
}
.coupon h2 {
  height: 51px;
  line-height: 51px;
  font-size: 16px;
  font-weight: bold;
  color: #5957ff;
  text-align: center;
  border-bottom: 1px #e4e5e5 solid;
}
.close {
  position: absolute;
  top: 17px;
  right: 22px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  opacity: 0.6;
}
.close img {
  width: 16px;
  height: 16px;
}
.coupon_content {
  padding: 30px 0;
  text-align: center;
}
.coupon_content p {
  font-size: 16px;
  color: #909090;
}
.coupon_content input {
  width: 155px;
  height: 42px;
  display: block;
  margin: 19px auto 28px;
  border: 1px #e4e5e5 solid;
  border-radius: 8px;
  padding: 0 15px;
  transition: 0.3s;
  text-align: center;
}
.coupon_content input:focus {
  border-color: #5857ff;
}
.coupon_content input::-ms-input-placeholder {
  text-align: center;
}
.coupon_content input::-webkit-input-placeholder {
  text-align: center;
}
.coupon_content >>> .el-button--primary {
  width: 119px;
  height: 36px;
  background-color: #5857ff;
  line-height: 1;
}
.close {
  position: absolute;
  top: 17px;
  right: 22px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  opacity: 0.6;
}
.close img {
  width: 16px;
  height: 16px;
}
</style>