<template>
  <div class="sidebar sidebar_r" :style="{ right: left + 'px' }">
    <div @click="refreshFun('downLoad')" class="sidebar_li">
      <div class="zongj" v-if="total > 0">
        <span>{{ total > 99 ? "99+" : total }}</span>
      </div>
      <img src="../assets/images/list@2x.png" alt="" />
      <p>下载清单</p>
    </div>
    <div @click="refreshFun('faq')" class="sidebar_li">
      <img src="../assets/images/Q@2x.png" alt="" />
      <p>常见问题</p>
    </div>
    <div class="sidebar_li" @click="contactqq">
      <img src="../assets/images/qq.png" alt="" />
      <p>联系我们</p>
    </div>

    <div class="sidebar_top" @click="topBackFun">
      <img src="../assets/images/top@2x.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "sidebar",

  computed: {
    left() {
      var left = 0;
      if (window.innerWidth - 1400 > 128) {
        if(this.$route.name=="deformation"){
          left = ((window.innerWidth - 1400) / 2 - 64) / 2/2;
        }else{
          left = ((window.innerWidth - 1400) / 2 - 64) / 2;
        }
        
      } else {
        left = 0;
      }
      console.log(left);
      return left;
    },
  },
  data() {
    return {
      total: "",
      qq: "",
    };
  },
  mounted() {
    if (localStorage.getItem("total") && localStorage.getItem("access_token")) {
      this.total = localStorage.getItem("total");
    } else {
      this.total = 0;
    }
    if (localStorage.getItem("access_token")) {
      this.listFun();
    }
    this.getqq();
  },
  methods: {
    listFun() {
      var that = this;
      this.$api.POST(this.$face.download_cartIndex, "", function (res) {
        that.total = res.data.total;
        localStorage.setItem("total", res.data.total);
      });
    },
    getqq() {
      var that = this;

      this.$api.POST(this.$face.getqq, {}, function (res) {
        that.qq = res.data.qq;
        console.log(that.qq);
      });
    },
    topBackFun() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    contactqq() {
      //window.open('tencent://message/?Menu=yes&uin=' + this.qq + '&Site=https://new.zitisheji.com');
      window.open(
        "http://dct.zoosnet.net/LR/Chatpre.aspx?id=DCT52374225&lng=cn"
      );
    },
    refreshFun(e) {
      // this.$router.push({
      // 	name: 'refresh',
      // 	query: {
      // 		usernext: e
      // 	}
      // })
      let routeUrl = this.$router.resolve({
        name: e,
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style scoped>
.sidebar_r {
  right: 2%;
  left: auto;
}
</style>
